<template>
  <div>
    <div class="foot">
      <div class="center"><span>Copyright 2019 </span><b>合肥充充数字科技有限公司</b><span> All Rights Reserved 版权所有</span></div>
      <div class="center">
        <span> 网站地图 </span>
        <span> 小鸡充值 </span>
        <span> 友情链接 </span>
      </div>
      <div class="center" @click="handleLink('http://beian.miit.gov.cn/', 'a')">皖ICP备18000777号-1</div>
      <div class="center" @click="handleLink('http://beian.miit.gov.cn/', 'a')">皖ICP备18000777号-2</div>
      <div class="center" @click="handleLink('https://res1.32ka.com/res/bannerImages/zhengzhi.jpg', 'a')">增值电信业务经营许可证：皖B2-20190247</div>
      <div class="center" @click="handleLink('https://res1.32ka.com/res/bannerImages/yinye.jpg', 'a')">营业执照</div>
      <div class="center" @click="handleLink('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34011102001539', 'a')">【皖公安备案34011102001539号】</div>
      <div class="center" @click="handleLink('http://gs.ccm.mct.gov.cn/lic/321a389a0be54554ab2afddd3a44e4e7', 'a')">皖网文【2018】2504-047号</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    // 链接处理
    handleLink(url, type) {
      if (type === "r") {
        this.$router.push(url)
      } else {
        window.open(url)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.foot {
  width: 100%;
  padding: 30px 0;
  background: #fff url(../assets/index/foot_bg.png) repeat-x left top;
}
.center {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #b5b2b2;
  margin-top: 10px;
}
</style>
