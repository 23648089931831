<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 10:08:58
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-22 10:03:13
-->
<template>
  <div id="app">
    <comm-head />
    <div class="app-content">
      <router-view />
    </div>
    <comm-foot v-if="flag" />
  </div>
</template>
<script>
import CommHead from "./components/commHead.vue"
import CommFoot from "./components/commFoot.vue"
export default {
  components: { CommHead, CommFoot },
  data() {
    return {
      flag: true,
    }
  },
  provide: {},
  watch: {
    $route(newVal) {
      if (newVal.name === "notFound") {
        this.flag = false
      } else {
        this.flag = true
      }
    },
  },
  mounted() {
    let isPhone = false
    if (document.body.offsetWidth > 1000) {
      isPhone = true
    } else {
      isPhone = false
    }
    this.$store.commit("changeIsPhone", isPhone)
    window.addEventListener("resize", () => {
      let flag = false
      if (document.body.offsetWidth > 1000) {
        flag = true
      } else {
        flag = false
      }
      this.$store.commit("changeIsPhone", flag)
    })
  },
}
</script>

<style lang="scss">
.app-content {
  width: 100%;
  position: relative;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ccc;
}
::-webkit-scrollbar-thumb:hover {
  background: #b8b8b8;
}
</style>
