<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 10:08:58
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-23 14:38:23
-->
<template>
  <div>
    <div v-if="$store.getters.getIsPhone" class="head" :style="{ backgroundColor: bg ? bg : getColor(tabKey) }">
      <div class="head-box">
        <img src="../assets/index/logo1.png" alt="" />
        <div class="head-box_tab">
          <div
            class="tabs"
            :class="{ 'check-tab': tabKey === item.key }"
            :style="{ color: tabKey === 'contact' ? '#000' : '#fff', borderBottom: tabKey === item.key && tabKey === 'contact' ? '2px solid #000' : '' }"
            v-for="item in tabData"
            :key="item.key"
            @click="hanleTab(item)">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端布局 -->
    <div v-else class="head2" :style="{ backgroundColor: bg ? bg : getColor(tabKey), height: listShow ? '100vh' : '15vw' }">
      <div class="head-box2">
        <img src="../assets/index/logo1.png" alt="" />
        <div class="list" @click="listShow = !listShow">
          <div class="list-item list-line1" :style="listShow ? { top: '1vw', transform: 'rotate(45deg)' } : {}"></div>
          <div class="list-item list-line2" :style="listShow ? { transform: 'rotate(45deg)' } : {}"></div>
          <div class="list-item list-line3" :style="listShow ? { top: '1vw', transform: 'rotate(-45deg)' } : {}"></div>
        </div>
      </div>
      <div class="head-list" v-for="item in tabData" :key="item.key" :class="{ 'check-tab': tabKey === item.key }">
        <div class="list-item" @click="hanleTab(item)" :style="{ color: tabKey === 'contact' ? '#000' : '#fff' }">{{ item.title }}</div>
      </div>
    </div>
    <div :style="{ width: '100%', height: $store.getters.getIsPhone ? '100px' : '15vw' }"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabData: [
        { key: "index", title: "首页", link: "/" },
        { key: "recharge", title: "充值鸡", link: "/recharge" },
        { key: "news", title: "鸡思广益", link: "/news" },
        { key: "recruit", title: "进驻鸡窝 ", link: "/recruit" },
        { key: "contact", title: "联系小鸡 ", link: "/contact" },
      ],
      tabKey: "index",
      bg: "",
      listShow: false,
    }
  },
  methods: {
    hanleTab(item) {
      this.tabKey = item.key
      this.$router.push(item.link)
      if (!this.$store.getters.getIsPhone) {
        this.listShow = false
      }
    },
    getColor(str) {
      switch (str) {
        case "index":
        case "recharge":
        case "news":
        case "down":
          return "#00b0ec"
        case "recruit":
          return "#ff6245"
        case "contact":
          return "#fbd92a"
        default:
          return "#00b0ec"
      }
    },
  },
  watch: {
    $route(newVal) {
      if (newVal.name === "notFound") {
        this.bg = "#fbd92a"
      } else {
        this.bg = ""
      }
    },
  },
  mounted() {
    this.tabKey =  sessionStorage.getItem('tabKey')|| 'index'
  },
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 99;
  .head-box {
    max-width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    justify-content: space-between;
    img {
      width: 174px;
    }
    .head-box_tab {
      display: flex;
      .tabs {
        padding: 10px 0;
        margin: 0 20px;
        color: #fff;
        user-select: none;
        cursor: pointer;
        opacity: 0.7;
        font-size: 20px;
      }
      .check-tab {
        border-bottom: 2px solid #fff;
        opacity: 1;
        font-weight: bold;
      }
    }
  }
}
.head2 {
  width: 100%;
  position: fixed;
  transition: all 0.2s;
  z-index: 999;
  overflow: hidden;
  .head-box2 {
    width: 100%;
    height: 15vw;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    img {
      width: 20%;
    }
    .list {
      width: 6vw;
      height: 3vw;
      position: relative;
      cursor: pointer;
      .list-item {
        position: absolute;
        width: 100%;
        height: 0.5vw;
        // top: 20px;
        background-color: #fff;
        transition: all 0.5s;
      }
    }
    .list-line1 {
      top: 0;
    }
    .list-line2 {
      top: 1vw;
    }
    .list-line3 {
      top: 2vw;
    }
  }
  .head-list {
    width: 100%;
    height: 10vw;
    padding: 0 20px;
    opacity: 0.7;
    box-sizing: border-box;
    .list-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.8vw;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
    }
  }
  .check-tab {
    opacity: 1;
    font-weight: bold;
  }
}
</style>
