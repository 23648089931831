/*
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 10:08:58
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-23 14:42:00
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'notFound',
    meta: { title: '错误页', name: 'index' },
    component: () => import('../views/notFound.vue')
  },
  {
    path: '/',
    name: 'index',
    meta: { title: '小鸡充值-充值超便宜', name: 'index' },
    component: () => import('../views/index.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: { title: '充值鸡', name: 'recharge' },
    component: () => import('../views/recharge.vue')
  },
  {
    path: '/news',
    name: 'news',
    redirect: "/news/index",
    meta: { title: '鸡思广益', name: 'news' },
    component: () => import('../views/news.vue'),
    children: [
      {
        path: '/news/index',
        name: 'newsIndex',
        meta: { title: '鸡思广益', name: 'news' },
        component: () => import('../views/new/index.vue'),
      },
      {
        path: '/news/strategy',
        name: 'newsStrategy',
        meta: { title: '游戏攻略', name: 'news' },
        component: () => import('../views/new/strategy.vue'),
      },
      {
        path: '/news/report',
        name: 'newsReport',
        meta: { title: '新闻中心', name: 'news' },
        component: () => import('../views/new/report.vue'),
      },
      {
        path: '/news/info',
        name: 'newsInfo',
        meta: { title: '详情', name: 'news' },
        component: () => import('../views/new/info.vue'),
      }
    ]
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: { title: '进驻鸡窝', name: 'recruit' },
    component: () => import('../views/recruit.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { title: '联系小鸡', name: 'contact' },
    component: () => import('../views/contact.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, _, next) => {
  // 滚动置顶
  window.scrollTo(0, 0)
  // 标题切换
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.name) {
    sessionStorage.setItem('tabKey', to.meta.name)
  }
  next()
})

export default router
