import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPhone: false, // 布局尺寸
  },
  getters: {
    getIsPhone(state) {
      return state.isPhone
    }
  },
  mutations: {
    changeIsPhone(state, val) {
      state.isPhone = val
    }
  },
  actions: {
  },
  modules: {
  }
})
